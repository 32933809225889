.vgt-table.striped tbody tr:nth-of-type(odd) {
  background-color: rgba(51, 68, 109, .03)
}

.vgt-right-align {
  text-align: right
}

.vgt-left-align {
  text-align: left
}

.vgt-center-align {
  text-align: center
}

.vgt-pull-left {
  float: left !important
}

.vgt-pull-right {
  float: right !important
}

.vgt-clearfix::after {
  display: block;
  content: "";
  clear: both
}

.vgt-responsive {
  width: 100%;
  position: relative
}

.vgt-text-disabled {
  color: #909399
}

.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px
}

.vgt-wrap {
  position: relative
}

.vgt-fixed-header {
  position: absolute;
  z-index: 10;
  overflow-x: auto
}

table.vgt-table {
  font-size: 16px;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  border: none;
  border-spacing: 0;
}

table.vgt-table td {
  padding: 12px 16px;
  vertical-align: top;
  border-bottom: 1px solid $gray;
  color: $dark;
  border-left: 1px solid transparent;
  text-align: left;
  font-size: 14px;
  line-height: 20px;

  +below(576px) {
    border-left: 1px solid $gray;
    border-top: none;
  }

  a {
    color $orange
    cursor pointer

    &:hover
    &:focus {
      text-decoration underline
    }
  }
}

.vgt-table th.line-numbers,
.vgt-table th.vgt-checkbox-col {
  border 1px solid $gray
  border-bottom none
  width auto !important

  input {
    float none
    margin 0
  }
}

table.vgt-table tr.clickable {
  cursor: pointer
}

table.vgt-table tr.clickable:hover {
  background-color: #f1f5fd
}

.vgt-table th {
  padding: 12px 15px;
  vertical-align: middle;
  position: relative;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
}

.vgt-table th.sortable button {
  display none
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.vgt-table th.sortable button:focus {
  outline: 0
}

.vgt-table th.sortable button:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: 6px;
  top: 50%;
  margin-top: -7px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #606266
}

.vgt-table th.sortable button:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: 6px;
  top: 50%;
  margin-bottom: -7px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #606266
}

.vgt-table th.line-numbers, .vgt-table th.vgt-checkbox-col {
  padding: 16px;
  word-wrap: break-word;
  width: 25px !important;
  text-align: center;
  border-bottom: 1px solid $gray;

  input {
    height auto
  }
}

.vgt-table th.filter-th {
  padding: .75em .75em .75em .75em
}

.vgt-table th.vgt-row-header {
  border-bottom: 2px solid $gray;
  border-top: 2px solid $gray;
  background-color: #fafafb
}

.vgt-table th.vgt-row-header .triangle {
  width: 24px;
  height: 24px;
  border-radius: 15%;
  position: relative;
  margin: 0 8px
}

.vgt-table th.vgt-row-header .triangle:after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: -3px;
  transition: .3s ease transform
}

.vgt-table th.vgt-row-header .triangle.expand:after {
  transform: rotate(90deg)
}

.vgt-table thead th {
  vertical-align: bottom;
  padding-right: 1.5em;
  background: $gray;
  color: $dark;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  border-color: transparent;
  border-bottom: 1px solid $gray;

  &:first-child {
    border-top-left-radius 10px
  }

  &:last-child {
    border-top-right-radius 10px
  }
}

.vgt-table thead th.vgt-checkbox-col {
  vertical-align: middle
}

.vgt-table thead th.sorting-asc button:after {
  border-bottom: 5px solid #409eff
}

.vgt-table thead th.sorting-desc button:before {
  border-top: 5px solid #409eff
}

.vgt-input, .vgt-select {
  width: 100%;
  height: 32px;
  line-height: 1;
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 12px;
  color: #606266;
  border-radius: 4px;
  box-sizing: border-box;
  background-image: none;
  background-color: #fff;
  border: 1px solid $gray;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1)
}

.vgt-input::placeholder, .vgt-select::placeholder {
  color: #606266;
  opacity: .3
}

.vgt-input:focus, .vgt-select:focus {
  outline: 0;
  border-color: #409eff
}

.vgt-loading {
  position: absolute;
  width: 100%;
  z-index: 10;
  margin-top: 117px
}

.vgt-loading__content {
  background-color: #c0dfff;
  color: #409eff;
  padding: 7px 30px;
  border-radius: 3px
}

.vgt-inner-wrap.is-loading {
  opacity: .5;
  pointer-events: none
}

//.vgt-table.bordered td, .vgt-table.bordered th {
//  border: 1px solid $gray
//}

.vgt-table.bordered th.vgt-row-header {
  border-bottom: 3px solid $gray
}

.vgt-wrap.rtl {
  direction: rtl
}

.vgt-wrap.rtl .vgt-table thead th, .vgt-wrap.rtl .vgt-table.condensed thead th {
  padding-left: 1.5em;
  padding-right: .75em
}

.vgt-wrap.rtl .vgt-table th.sorting-asc:after, .vgt-wrap.rtl .vgt-table th.sorting:after {
  margin-right: 5px;
  margin-left: 0
}

.vgt-wrap.rtl .vgt-table th.sortable:after, .vgt-wrap.rtl .vgt-table th.sortable:before {
  right: inherit;
  left: 6px
}

.vgt-table.condensed td, .vgt-table.condensed th.vgt-row-header {
  padding: .4em .4em .4em .4em
}

@media (max-width: 576px) {
  .vgt-compact * {
    box-sizing: border-box
  }

  .vgt-compact tbody, .vgt-compact td, .vgt-compact tr {
    display: block;
    width: 100%
  }

  .vgt-compact thead {
    display: none
  }

  .vgt-compact tr {
    margin-bottom: 15px
  }

  .vgt-compact td {
    border-right: 1px solid $gray;
    position: relative;
    text-align: right !important;

    &:first-child {
      border-top: 1px solid $gray;
    }
  }

  .vgt-compact td:before {
    content: attr(data-label);
    position: relative;
    float: left;
    left: 0;
    width: 40%;
    padding-left: 10px;
    font-weight: 700;
    text-align: left
  }

  .vgt-compact th.line-numbers {
    width: 100% !important;
    display: block;
    padding: .3em 1em !important
  }
}

.vgt-global-search {
  padding: 5px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  border: 1px solid $gray;
  border-bottom: 0;
  background: linear-gradient(#f4f5f8, #f1f3f6)
}

.vgt-global-search form {
  display: flex
}

.vgt-global-search form label {
  margin-top: 3px
}

.vgt-global-search__input {
  position: relative;
  padding-left: 40px;
  flex-grow: 1
}

.vgt-global-search__input .input__icon {
  position: absolute;
  left: 0;
  max-width: 32px
}

.vgt-global-search__input .input__icon .magnifying-glass {
  margin-top: 3px;
  margin-left: 8px;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #494949;
  position: relative;
  border-radius: 50%
}

.vgt-global-search__input .input__icon .magnifying-glass:before {
  content: "";
  display: block;
  position: absolute;
  right: -7px;
  bottom: -5px;
  background: #494949;
  width: 8px;
  height: 4px;
  border-radius: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg)
}

.vgt-global-search__actions {
  margin-left: 10px
}

.vgt-selection-info-row {
  background: #fdf9e8;
  padding: 5px 16px;
  font-size: 13px;
  border-top: 1px solid $gray;
  border-left: 1px solid transparent;
  border-right: 1px solid $gray;
  color: #d3aa3b;
  font-weight: 700
}

.vgt-selection-info-row a {
  font-weight: 700;
  display: inline-block;
  margin-left: 10px
}

.vgt-wrap__actions-footer {
  border: 1px solid $gray
}

.vgt-wrap__footer {
  color: #606266;
  font-size: 1.1rem;
  padding: 1em;
  border: 1px solid $gray;
  background: linear-gradient(#f4f5f8, #f1f3f6)
}

.vgt-wrap__footer .footer__row-count__label, .vgt-wrap__footer .footer__row-count__select {
  display: inline-block;
  vertical-align: middle
}

.vgt-wrap__footer .footer__row-count__label {
  font-size: 1.1rem
}

.vgt-wrap__footer .footer__row-count__select {
  width: auto;
  font-size: 1.1rem;
  margin-left: 8px;
  padding: 3px;
  -webkit-appearance: listbox;
  -moz-appearance: listbox
}

.vgt-wrap__footer .footer__navigation {
  font-size: 1.1rem
}

.vgt-wrap__footer .footer__navigation > button:first-of-type {
  margin-right: 16px
}

.vgt-wrap__footer .footer__navigation__info, .vgt-wrap__footer .footer__navigation__page-btn, .vgt-wrap__footer .footer__navigation__page-info {
  display: inline-block;
  vertical-align: middle;
  color: #909399
}

.vgt-wrap__footer .footer__navigation__page-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
  border: none;
  text-decoration: none;
  color: #606266;
  font-weight: 700;
  white-space: nowrap;
  vertical-align: middle
}

.vgt-wrap__footer .footer__navigation__page-btn:hover {
  cursor: pointer
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled, .vgt-wrap__footer .footer__navigation__page-btn.disabled:hover {
  opacity: .5;
  cursor: not-allowed
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.left:after, .vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.left:after {
  border-right-color: #606266
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.right:after, .vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.right:after {
  border-left-color: #606266
}

.vgt-wrap__footer .footer__navigation__page-btn span {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1rem
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  width: 24px;
  height: 24px;
  border-radius: 15%;
  position: relative;
  margin: 0;
  display: inline-block;
  vertical-align: middle
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron:after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
  border-right: 6px solid #409eff;
  margin-left: -3px
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid #409eff;
  margin-left: -3px
}

.vgt-wrap__footer .footer__navigation__info, .vgt-wrap__footer .footer__navigation__page-info {
  display: inline-block;
  margin: 0 16px
}

.vgt-wrap__footer .footer__navigation__page-info span {
  display: inline-block;
  vertical-align: middle
}

.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  width: 30px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  margin: 0 10px;
  font-weight: 700
}

@media only screen and (max-width: 750px) {
  .vgt-wrap__footer .footer__navigation__info {
    display: none
  }

  .vgt-wrap__footer .footer__navigation__page-btn {
    margin-left: 16px
  }
}

.vgt-table.nocturnal {
  border: 1px solid #435169;
  background-color: #324057
}

.vgt-table.nocturnal tr.clickable:hover {
  background-color: #445168
}

.vgt-table.nocturnal td {
  border-bottom: 1px solid #435169;
  color: #c7ced8
}

.vgt-table.nocturnal th.line-numbers, .vgt-table.nocturnal th.vgt-checkbox-col {
  color: #c7ced8;
  border-right: 1px solid #435169;
  background: linear-gradient(#2c394f, #2c394f)
}

.vgt-table.nocturnal thead th {
  color: #c7ced8;
  border-bottom: 1px solid #435169;
  background: linear-gradient(#2c394f, #2c394f)
}

.vgt-table.nocturnal thead th.sortable:before {
  border-top-color: #3e5170
}

.vgt-table.nocturnal thead th.sortable:after {
  border-bottom-color: #3e5170
}

.vgt-table.nocturnal thead th.sortable.sorting-asc {
  color: #fff
}

.vgt-table.nocturnal thead th.sortable.sorting-asc:after {
  border-bottom-color: #409eff
}

.vgt-table.nocturnal thead th.sortable.sorting-desc {
  color: #fff
}

.vgt-table.nocturnal thead th.sortable.sorting-desc:before {
  border-top-color: #409eff
}

.vgt-table.nocturnal.bordered td, .vgt-table.nocturnal.bordered th {
  border: 1px solid #435169
}

.vgt-table.nocturnal .vgt-input, .vgt-table.nocturnal .vgt-select {
  color: #c7ced8;
  background-color: #232d3f;
  border: 1px solid #435169
}

.vgt-table.nocturnal .vgt-input::placeholder, .vgt-table.nocturnal .vgt-select::placeholder {
  color: #c7ced8;
  opacity: .3
}

.vgt-wrap.nocturnal .vgt-wrap__footer {
  color: #c7ced8;
  border: 1px solid #435169;
  background: linear-gradient(#2c394f, #2c394f)
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__row-count {
  position: relative
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__row-count__label {
  color: #8290a7
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__row-count__select {
  color: #c7ced8;
  background: #232d3f;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 15px;
  padding-left: 10px;
  border-radius: 3px;
  text-align: center
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__row-count__select:focus {
  border-color: #409eff
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__row-count::after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  right: 6px;
  top: 50%;
  margin-top: -1px;
  border-top: 6px solid #c7ced8;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: none;
  pointer-events: none
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__navigation__page-btn {
  color: #c7ced8
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.left:after, .vgt-wrap.nocturnal .vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.left:after {
  border-right-color: #c7ced8
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.right:after, .vgt-wrap.nocturnal .vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.right:after {
  border-left-color: #c7ced8
}

.vgt-wrap.nocturnal .vgt-wrap__footer .footer__navigation__info, .vgt-wrap.nocturnal .vgt-wrap__footer .footer__navigation__page-info {
  color: #8290a7
}

.vgt-wrap.nocturnal .vgt-global-search {
  border: 1px solid #435169;
  background: linear-gradient(#2c394f, #2c394f)
}

.vgt-wrap.nocturnal .vgt-global-search__input .input__icon .magnifying-glass {
  border: 2px solid #3f4c63
}

.vgt-wrap.nocturnal .vgt-global-search__input .input__icon .magnifying-glass:before {
  background: #3f4c63
}

.vgt-wrap.nocturnal .vgt-global-search__input .vgt-input, .vgt-wrap.nocturnal .vgt-global-search__input .vgt-select {
  color: #c7ced8;
  background-color: #232d3f;
  border: 1px solid #435169
}

.vgt-wrap.nocturnal .vgt-global-search__input .vgt-input::placeholder, .vgt-wrap.nocturnal .vgt-global-search__input .vgt-select::placeholder {
  color: #c7ced8;
  opacity: .3
}

.vgt-table.black-rhino {
  border: 1px solid #435169;
  background-color: #dfe5ee
}

.vgt-table.black-rhino tr.clickable:hover {
  background-color: #fff
}

.vgt-table.black-rhino td {
  border-bottom: 1px solid #bbc5d6;
  color: #49515e
}

.vgt-table.black-rhino th.line-numbers, .vgt-table.black-rhino th.vgt-checkbox-col {
  color: #dae2f0;
  border-right: 1px solid #435169;
  background: linear-gradient(#4c5c79, #4e5d7c)
}

.vgt-table.black-rhino thead th {
  color: #dae2f0;
  text-shadow: 1px 1px #3e5170;
  border-bottom: 1px solid #435169;
  background: linear-gradient(#4c5c79, #4e5d7c)
}

.vgt-table.black-rhino thead th.sortable:before {
  border-top-color: #607498
}

.vgt-table.black-rhino thead th.sortable:after {
  border-bottom-color: #607498
}

.vgt-table.black-rhino thead th.sortable.sorting-asc {
  color: #fff
}

.vgt-table.black-rhino thead th.sortable.sorting-asc:after {
  border-bottom-color: #409eff
}

.vgt-table.black-rhino thead th.sortable.sorting-desc:before {
  border-top-color: #409eff
}

.vgt-table.black-rhino.bordered td {
  border: 1px solid #bbc5d6
}

.vgt-table.black-rhino.bordered th {
  border: 1px solid #435169
}

.vgt-table.black-rhino .vgt-input, .vgt-table.black-rhino .vgt-select {
  color: #dae2f0;
  background-color: #34445f;
  border: 1px solid transparent
}

.vgt-table.black-rhino .vgt-input::placeholder, .vgt-table.black-rhino .vgt-select::placeholder {
  color: #dae2f0;
  opacity: .3
}

.vgt-wrap.black-rhino .vgt-wrap__footer {
  color: #dae2f0;
  border: 1px solid #435169;
  background: linear-gradient(#4c5c79, #4e5d7c)
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__row-count {
  position: relative;
  padding-right: 3px
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__row-count__label {
  color: #98a5b9
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__row-count__select {
  color: #49515e;
  background: #34445f;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 15px;
  padding-left: 5px;
  border-radius: 3px
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__row-count__select::-ms-expand {
  display: none
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__row-count__select:focus {
  border-color: #409eff
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__row-count::after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  right: 6px;
  top: 50%;
  margin-top: -1px;
  border-top: 6px solid #49515e;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: none;
  pointer-events: none
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__navigation__page-btn {
  color: #dae2f0
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.left:after, .vgt-wrap.black-rhino .vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.left:after {
  border-right-color: #dae2f0
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.right:after, .vgt-wrap.black-rhino .vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.right:after {
  border-left-color: #dae2f0
}

.vgt-wrap.black-rhino .vgt-wrap__footer .footer__navigation__info, .vgt-wrap.black-rhino .vgt-wrap__footer .footer__navigation__page-info {
  color: #dae2f0
}

.vgt-wrap.black-rhino .vgt-global-search {
  border: 1px solid #435169;
  background: linear-gradient(#4c5c79, #4e5d7c)
}

.vgt-wrap.black-rhino .vgt-global-search__input .input__icon .magnifying-glass {
  border: 2px solid #3f4c63
}

.vgt-wrap.black-rhino .vgt-global-search__input .input__icon .magnifying-glass:before {
  background: #3f4c63
}

.vgt-wrap.black-rhino .vgt-global-search__input .vgt-input, .vgt-wrap.black-rhino .vgt-global-search__input .vgt-select {
  color: #dae2f0;
  background-color: #44516c;
  border: 1px solid transparent
}

.vgt-wrap.black-rhino .vgt-global-search__input .vgt-input::placeholder, .vgt-wrap.black-rhino .vgt-global-search__input .vgt-select::placeholder {
  color: #dae2f0;
  opacity: .3
}

