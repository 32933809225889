.widget {
  padding 24px 32px
  background $gray
  border 1px solid $gray
  color $blue
  border-radius 10px

  &__content {
    display flex
    flex-direction column
  }

  &__title {
    font-weight bold
    font-size 22px
  }

  &__subtitle {
    margin-top 12px
    font-weight normal
    font-size 18px
  }
}
