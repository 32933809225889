.accordion
  list-style none
  margin 0
  padding 0

  &__item
    display flex
    flex-direction column
    margin-bottom 10px
    transition all 0.3s

    &.active .accordion__header
      background $dark
      color $white

    &.active .accordion__body
      display flex

    &.active .accordion__minus
      display block

    &.active .accordion__plus
      display none

  &__header
    display flex
    align-items center
    background $gray
    padding 15px
    border 1px solid $darkgray
    border-radius 10px
    cursor pointer
    justify-content space-between
    font-family font_bold
    transition all 0.3s

    &:hover
      background $darkgray
      color $blue

  &__minus
    display none

  &__body
    flex-direction column
    margin-top 20px
    display none
