.page-link
  display flex
  align-items center
  justify-content center
  width 50px
  height 50px

.pagination
  padding 0
  display flex
  justify-content center
  align-items center
  list-style none
  margin 24px auto 32px
  flex-wrap wrap

  &__section
    background $white
    width 100%
    display flex
    justify-content center
    align-items center
    padding-bottom 92px

  &__container
    display flex
    align-items center
    justify-content center

  &__icon
    display flex
    align-items center
    justify-content center

    &--rotated
      transform rotate(180deg)

    svg path
      fill $dark

  &__link
    width 100%
    height 100%
    display flex
    align-items center
    justify-content center
    font-weight normal
    font-size 16px
    line-height 18px
    color $dark
    border-radius: 10px;

    &--disabled
      cursor: initial;

    &:not(&--disabled):not(&--active):hover
    &:not(&--disabled):not(&--active):focus
      background $gray
      color $darker

    &--active
      cursor initial
      background $orange
      color $white
      box-shadow 0 10px 15px rgba($blue, 0.2)
