.no-overflow
  overflow hidden

.swal2-title
  font-weight bold !important
  font-size 18px !important
  line-height 21px !important
  color $black !important

.modal
  fixed top left
  opacity 1
  width 100%
  height 100vh
  display flex
  align-items flex-start
  justify-content center
  pointer-events auto
  transition all 0.3s
  z-index 999
  overflow-y: auto;

  &__list
    list-style none

    li
      a
        display flex
        border-top 1px solid $darkgray
        padding 10px 15px

        &.router-link-exact-active.router-link-active
        &.active {
          background $gray
        }
        &:hover
        &:focus
          background $gray
          color $dark

  &__background
    fixed top left
    width 100%
    height 100vh
    cursor pointer
    background alpha($dark, 50%)
    z-index 1

  &__container
    background $white
    width 100%
    margin: 30px 0;
    max-width 400px
    border-radius 10px
    box-shadow alpha($dark, 20%) 0 30px 60px
    overflow auto
    transition transform 0.3s
    z-index 2

  &__footer
    border-top 1px solid $darkgray
    padding 10px 15px

  &.modal_hidden
    opacity 0
    pointer-events none
    z-index -999

.modal__header
  display flex
  align-items center
  justify-content space-between
  padding 15px

  span:not(.modal__close)
    flex 1
    font-size 1.3em
    font-family font_bold
    color $dark

  .modal__close
    width 30px
    height 30px
    display flex
    align-items center
    justify-content center
    border-radius 10px
    cursor pointer
    transition background 0.3s

    svg
      width 10px
      opacity 0.5
      transition all 0.3s

    &:hover
    &:focus
      background $gray

      svg
        stroke $dark
        opacity 1

.vue-notification
  margin 0 15px 15px 0 !important

  &.error
    background: $red !important;
    border-left-color: darken($red, 50%) !important;

  &.warn
    background: $orange !important;
    border-left-color: darken($orange, 50%) !important;

  &.success
    background: $green !important;
    border-left-color: darken($green, 50%) !important;
