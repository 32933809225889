.table
  &-drop
    display inline-flex
    align-items center
    justify-content flex-start
    position relative
    background $gray
    border 1px solid $darkgray
    box-sizing border-box
    border-radius 5px

    &:hover
    &:focus-within
      border-color $nightgray

    &__button
      padding 8px 12px
      border-right 1px solid $darkgray
      font-size 12px
      line-height 14px
      color $dark !important

    &__trigger
      width 29px
      height 30px
      display flex
      align-items center
      justify-content center
      border none
      padding 0
      background none
      outline none
      cursor pointer

      &:hover + .table-drop__list
      &:focus + .table-drop__list
        display flex


    &__list
      transition .2s
      absolute left bottom
      display none
      transform translateY(100%)
      background $gray
      border-radius 5px
      flex-direction column
      list-style none
      padding 0
      width 100%
      z-index 3
      box-shadow 0 15px 30px rgba(0, 0, 0, 0.1)

      &:hover
      &:focus
      &:focus-within
        display flex

      li
        display flex
        align-items center
        justify-content center

        a
          display flex
          align-items center
          width 100%
          padding 10px 12px
          font-size 12px
          line-height 14px
          color $dark !important

          &:hover
          &:focus
            background $white
            color $dark

//  table
//    background $white
//    border-radius 10px
//    border 1px solid $darkgray
//    margin-bottom 0
//
//  &.draggable
//    .draggable--over
//      background lighten($darkgray, 10%) !important
//
//    .draggable-mirror
//      display none !important
//
//    tbody
//    tr
//      position relative
//
//  &__move
//    width 16px
//    height 16px
//
//  &__title
//    font-weight bold
//    font-size 16px
//    padding 16px 0 0
//
//  .check
//    width 32px
//
//    input[type="checkbox"]
//      height 13px
//      margin 0
//
//  thead
//    tr
//      background lighten($darkgray, 30%)
//      border-bottom 1px solid $darkgray
//
//      th
//        font-size 14px
//        line-height 18px
//        padding 10px 10px 12px
//        color $dark
//
//        &:not(:last-child)
//          border-right 1px solid $darkgray
//
//  tbody
//    tr
//      border-bottom 1px solid $darkgray
//
//      td
//        font-size 14px
//        line-height 18px
//        padding 5px 10px
//        color $dark
//        vertical-align: middle;
//
//        &:not(:last-child)
//          border-right 1px solid $darkgray
//
//  &-drop
//    display inline-flex
//    align-items center
//    justify-content flex-start
//    position relative
//    background $gray
//    border 1px solid $darkgray
//    box-sizing border-box
//    border-radius 10px
//
//    &:hover
//    &:focus-within
//      border-color $nightgray
//
//    &__button
//      padding 8px 12px
//      border-right 1px solid $darkgray
//      font-size 12px
//      line-height 14px
//      color $dark
//
//    &__trigger
//      width 29px
//      height 30px
//      display flex
//      align-items center
//      justify-content center
//      border none
//      padding 0
//      background none
//      outline none
//      cursor pointer
//
//      &:hover + .table-drop__list
//      &:focus + .table-drop__list
//        display flex
//
//
//    &__list
//      transition .2s
//      absolute left bottom
//      display none
//      transform translateY(100%)
//      background $gray
//      border-radius 10px
//      flex-direction column
//      list-style none
//      padding 0
//      width 100%
//      z-index 3
//      box-shadow 0 15px 30px rgba(0, 0, 0, 0.1)
//
//      &:hover
//      &:focus
//      &:focus-within
//        display flex
//
//      li
//        display flex
//        align-items center
//        justify-content center
//
//        a
//          display flex
//          align-items center
//          width 100%
//          padding 10px 12px
//          font-size 12px
//          line-height 14px
//          color $dark
//
//          &:hover
//          &:focus
//            background $white
//            color $dark
//
//.vgt-wrap
//  font-family font_regular
//
//.vgt-table th.line-numbers
//.vgt-table th.vgt-checkbox-col
//.vgt-table thead th
//  background $gray
//
//.vgt-table th.line-numbers
//.vgt-table th.vgt-checkbox-col
//.vgt-table th
//.vgt-table td
//  border-color $darkgray !important
//
//.vgt-table.bordered td
//.vgt-table.bordered th
//  border 1px solid $darkgray
//
//.vgt-selection-info-row
//  background $blue
//  border-color $blue
//  color $white
//
//  a
//    position: relative;
//    background: $gray;
//    border: 1px solid $gray;
//    color: $blue;
//    margin: 10px 20px;
//    padding: 2px 10px;
//    border-radius: 10px;
//    cursor: pointer;
//    display: inline-flex;
//    align-items: center;
//    justify-content: center;
//    text-align: center;
//    overflow: hidden;
//    transition: all 0.3s;
//    z-index: 1;
//    font-weight normal
//    font-size 1em
//
//.vgt-selection-info-row
//  display none
//
//.vgt-table thead th.vgt-checkbox-col
//  padding 0 12px
//
//.vgt-responsive
//  overflow visible
