.container
  container()

.link
  link($dark)

.hidden
  display none !important

.input__editor
  border: 1px solid #a2c6e9;
  border-radius: 10px;
  padding 0 0 20px

.input__button
  display inline-flex
  margin-top 10px

.input__error
  color $red
  font-size 12px
  display flex
  width 100%
  font-weight bold

.input__info
  color $dark
  font-size 12px
  line-break: anywhere;

  a
    text-decoration underline

/*Сетка*/

.row
  grid()

.row--width-fix
  grid()
  width calc(100% + 16px)

.col-1
  grid_item(100%)

.col-2
  grid_item(50%)

  +below(768px)
    grid_item(100%)

.col-3
  grid_item(33.333333%)

  +below(768px)
    grid_item(100%)

.col-4
  grid_item(25%)

  +below(960px)
    grid_item(50%)

  +below(768px)
    grid_item(100%)

.col-5
  grid_item(20%)

  +below(960px)
    grid_item(25%)

  +below(768px)
    grid_item(33.333333%)

  +below(480px)
    grid_item(100%)

._col-1
  grid_item(8.33333333%)

  +below(960px)
    grid_item(33.333333%)

  +below(768px)
    grid_item(50%)

  +below(480px)
    grid_item(100%)

  .form__item
    margin 0

._col-2
  grid_item(16.6666667%)

  +below(960px)
    grid_item(25%)

  +below(768px)
    grid_item(33.333333%)

  +below(480px)
    grid_item(100%)

  .form__item
    margin 0

._col-3
  grid_item(25%)
  +below(960px)
    grid_item(50%)

  +below(768px)
    grid_item(100%)

  .form__item
    margin 0

._col-4
  grid_item(33.3333333%)
  +below(768px)
    grid_item(100%)

  .form__item
    margin 0

._col-5
  grid_item(41.6666667)
  +below(768px)
    grid_item(100%)

  .form__item
    margin 0

._col-6
  grid_item(50%)
  +below(768px)
    grid_item(100%)

  .form__item
    margin 0

._col-7
  grid_item(58.3333333%)
  +below(768px)
    grid_item(100%)

  .form__item
    margin 0

._col-8
  grid_item(66.6666666%)
  +below(960px)
    grid_item(100%)

  .form__item
    margin 0

._col-9
  grid_item(75%)
  +below(960px)
    grid_item(100%)

  .form__item
    margin 0

._col-10
  grid_item(83.3333333%)
  +below(960px)
    grid_item(100%)

  .form__item
    margin 0

._col-11
  grid_item(91.6666666%)

  .form__item
    margin 0

._col-12
  grid_item(100%)

  .form__item
    margin 0

.flex
  display flex
