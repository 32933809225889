.tabs__head
  list-style none
  padding 0
  margin -5px -5px 15px
  display flex
  align-items flex-start
  flex-wrap wrap
  justify-content flex-start

  li {
    margin 5px
  }

.tabs__item
  button($gray, $dark)
  border 1px solid $darkgray

  &.active
    button($dark, $white)

  &.error
    button($red, $white)

.tabs__content
  display block
