/*Fonts*/
@font-face
    font-family 'Roboto-Regular'
    src url('../fonts/Roboto-Regular/Roboto-Regular.eot')
    src url('../fonts/Roboto-Regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular/Roboto-Regular.svg#Roboto-Regular') format('svg'),
        url('../fonts/Roboto-Regular/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular/Roboto-Regular.woff2') format('woff2')
    font-weight normal
    font-style normal
    font-display swap

@font-face
    font-family 'Roboto-Bold'
    src url('../fonts/Roboto-Bold/Roboto-Bold.eot')
    src url('../fonts/Roboto-Bold/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold/Roboto-Bold.svg#Roboto-Bold') format('svg'),
        url('../fonts/Roboto-Bold/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold/Roboto-Bold.woff2') format('woff2')
    font-weight normal
    font-style normal
    font-display swap

font_regular = 'Roboto-Regular'
font_bold = 'Roboto-Bold'