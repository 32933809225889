.ms
  border-radius 10px
  border 1px solid $darkgray
  background $gray
  padding 20px

  &__title
    display block
    font-family font_bold
    margin-bottom 20px
    font-size 1.3em
    color $dark

  &__list
    list-style none
    padding 0

  &__item
    cursor move
    position relative
    display flex
    align-items flex-end
    +below(640px)
      align-items: center;

    svg
      width 12px
      height 40px
      display inline-flex
      margin 0 10px 10px 0

    .form__row
      flex 1
      +below(640px)
        flex-direction: column;
        align-items: flex-start;

        .form__item
          width 100%

    .input__container
      margin-bottom 20px

      &:last-child
        margin-bottom 0

.move-icon
  display inline-flex
  align-items center
  justify-content center
  cursor move

  svg
    width 15px
    height 15px
    display inline-flex
    margin-top 22px