.select2-container
  .select2-selection--single
    height 40px
    border 1px solid darken($gray, 20%)

    .select2-selection__rendered
      line-height 36px

    .select2-selection__arrow
      height 40px

.select2-container--default
  .select2-selection--multiple
    border 1px solid darken($gray, 20%)
  
  &.select2-container--focus
    .select2-selection--multiple
      border 1px solid darken($gray, 40%)