.app
  display grid
  min-height 100vh

$header_height = 60px
$footer_height = 66px
.main
  display grid
  grid-template-rows: auto 1fr;
  min-height: 'calc(100vh - %s - %s)' % ($header_height $footer_height);

.page
  display grid
  padding 30px 0
  height 100%
  background $white

  &__edit
    flex 1
    width: 100%;

.content
  display flex
  align-items: flex-start;

  +below(768px)
    flex-direction column

.content__left
  flex 1

  +below(576px)
    width 100%

.content__right

  +below(768px)
    padding 30px 0 0

.main__buttons
.main__filters
  margin-bottom 30px
