.button
.btn
.swal2-styled.swal2-confirm
  button($orange, $white)
  outline none

  &--danger {
    button($red, $white)
  }
  &--small {
    padding: 6px 12px;
    height: 32px;
  }

  i {
    width 20px
    height 20px
    margin-left 10px

    svg {
      width 100%
      height 100%
      fill $white
    }
  }

  &.disabled {
    opacity 0.7
    cursor not-allowed
  }

.buttons_group
  display flex
  align-items center
  flex-wrap wrap
  margin-bottom -10px

  button
  a
    margin 0 10px 10px 0

  +below(480px)
    display block
    margin 0

    button
    a
      width 100%
      margin 0 0 10px