.breadcrumb
  padding 20px 0
  background $gray

  .container
    display flex
    align-items center
    justify-content space-between

    +below(768px)
      display block

.breadcrumb__title
  margin 0
  padding 0
  font-size 1.3em

.breadcrumb__list
  flex 1
  display flex
  flex-wrap wrap
  justify-content flex-end

  +below(768px)
    justify-content flex-start

  li
    list-style none

    a
      link($dark)

    &::after
      content '/'
      display inline-flex
      margin 0 10px


    &:last-child::after
        display none
